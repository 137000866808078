import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
// import { renderRoutes } from 'react-router-config';
import './App.scss';
import Cookies from 'js-cookie'
import axios from 'axios'
import Messages from './views/Messages/Messages';

const loading = () => <div className="animated fadeIn pt-3 text-center">Loading...</div>;

// Containers
const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));

// Pages
const Login = React.lazy(() => import('./views/Pages/Login'));
const Register = React.lazy(() => import('./views/Pages/Register'));
const Page404 = React.lazy(() => import('./views/Pages/Page404'));
const Page500 = React.lazy(() => import('./views/Pages/Page500'));

class App extends Component {
  constructor(props) {
    super(props)

    this.state = {
      userLoggedIn: null
    }
  }

  componentDidMount() {
    console.log('Checking if is logged')

    let token = Cookies.get('admin_token')

    if (!token) {
      this.setState({ userLoggedIn: false })
    }
    else {
      const onLogin = async (token) => {
        try {
          let response = await axios.post('https://online.korper.io/admin/login', { token }, { withCredentials: true })

          if (response.data.success) {
            Cookies.set('admin_token', response.data.token, { expires: 14, domain: 'korper.io' })

            this.setState({ userLoggedIn: true })
          }
          else {
            this.setState({ userLoggedIn: false })
          }
        }
        catch (err) {
          console.error(err)
          this.setState({ userLoggedIn: false })
        }
      }

      onLogin(token)
    }
  }

  render() {
    if (this.state.userLoggedIn === null) {
      return loading()
    }

    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
            <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
            <Route path="/messages/:customerId" component={Messages} />
            {this.state.userLoggedIn ?
              <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
              :
              <>
                <Route path="/login" name="Login" render={props => <Login {...props} onLogin={() => this.setState({ userLoggedIn: true })} />} />
                <Redirect from="/" to="/login" />
            

              </>
            }
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
