import React, { Component } from "react";
import axios from "axios";
import {
  Button,
  Col,
  Container,
  Row,
  Spinner,
  Card,
  CardBody,
} from "reactstrap";
import "./Messages.css";

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      messages: [],
      loading: true,
      customerId: this.props.match.params.customerId,
      customerName: "",
    };
  }

  componentDidMount() {
    this.fetchMessages();
  }

  fetchMessages = async () => {
    const { customerId } = this.state;
    try {
      const response = await axios.get(
        `https://online.korper.io/admin/customer/${customerId}/messages`,
        {
          withCredentials: true,
        }
      );
      if (response.data.success) {
        this.setState({
          messages: response.data.messages,
          loading: false,
          customerName: `${response.data.customer.firstName} ${response.data.customer.lastName}`,
        });
      } else {
        alert("Impossible de récupérer les messages");
        this.setState({ loading: false });
      }
    } catch (error) {
      console.error(error);
      alert("Erreur lors de la récupération des messages");
      this.setState({ loading: false });
    }
  };

  formatDate = (dateString) => {
    const date = new Date(dateString);
    const options = {
      day: "numeric",
      month: "numeric",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    };
    return date.toLocaleDateString("fr-FR", options);
  };

  renderMessages = () => {
    const { messages } = this.state;
    return messages.map((message) => (
      <Card key={message._id} className={`message-card ${message.author}`}>
        <CardBody>
          <div className="message-header">
            <strong>{message.user.name}</strong>
            <span className="message-date">
              {this.formatDate(message.createdAt)}
            </span>
          </div>
          {message.text && <p>{message.text}</p>}
          {message.image && (
            <img src={message.image} alt="Message" className="message-image" />
          )}
          {/* Handle other message types if necessary */}
        </CardBody>
      </Card>
    ));
  };

  render() {
    const { loading, customerName } = this.state;
    return (
      <Container className="messages-page">
        <Row>
          <Col>
            <Button
              color="secondary"
              onClick={() => this.props.history.goBack()}
              style={{ marginBottom: "20px" }}
            >
              &larr; Retour
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>Conversation avec {customerName}</h2>
            {loading ? (
              <Spinner color="primary" />
            ) : (
              <div className="messages-list">{this.renderMessages()}</div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Messages; 